import React, {useEffect, useState} from "react";
import {
    Layout,
    Typography,
    Button,
    List,
    Checkbox,
    Row,
    Col,
    Card,
    Input, message, Tabs, Space, Progress,
} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import Icon, {
    ArrowLeftOutlined,
    ClockCircleOutlined, LeftOutlined,
    LikeOutlined, LoadingOutlined, MessageOutlined, PlayCircleFilled,
    PlayCircleOutlined,
    ShareAltOutlined
} from "@ant-design/icons";
import Courses from "./courses";
import {getMealName, isMobile, round} from "../helpers";
import {useResizeEffect} from "../resize-effect";
import Api from "../api";
import {useTranslation} from "react-i18next";
import ReactPlayer from "react-player";
import moment from "moment/moment";

const {Content} = Layout;
const {Title, Text} = Typography;
const {TextArea} = Input;

const SingleCourseView = () => {
    const {t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();
    const [playVideo, setPlayVideo] = useState(false);
    const isMobile = useResizeEffect();
    const [course, setCourse] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        window.scrollTo(0, 0);

        loadCourse();
    }, []);

    const loadCourse = () => {
        Api.get('/v2/courses/' + id).then(res => {
            const courseData = res.data;

            // Apply availability logic based on course ID
            const isAvailable = id <= 4 || new Date() >= getNextMonthDate(course.id - 4);
            const availableDate = course.id <= 4 ? null : getNextMonthDate(course.id - 4);

            setCourse({
                ...courseData,
                isAvailable,
                availableDate,
            });

            setLoading(false);
        })
    }

    // Helper function to calculate the 1st of the next month
    const getNextMonthDate = (offset) => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + offset + 1; // Calculate the month based on ID offset
        return new Date(year, month, 1); // Constructs the 1st of the given month
    };

    const handleTaskToggle = (task, checked) => {
        Api.put(`/v2/tasks/${task.id}/complete`, {completed: !task.completed}).then((res) => {
            message.success(t(!task.completed ? 'task.completed' : 'task.uncompleted'));

            res.data.isAvailable = true;

            setCourse(res.data);
        }).catch(err => {
            console.error(err)
        });
    };

    const completedTasksCount = course && course.tasks.filter(task => task.completed).length;
    if (!loading && !course.isAvailable) {
        return <div></div>;
    }

    return (
        <div>
            <Row type="flex" justify="center">
                <Col span={24} md={20} xxl={16}>
                    {!isMobile && (
                        <Title level={isMobile ? 4 : 1}>{course.title}</Title>
                    )}

                    <Row type="flex" justify="center" gutter={20}>
                        <Col span={24} lg={16}>
                            <Card className="video-card-iframe" cover={!loading ?
                                <ReactPlayer
                                    url={course.video}
                                    style={{borderRadius: 12, overflow: "hidden"}}
                                    width="100%"
                                    controls={true}
                                    height={isMobile ? "200px" : "387px"}
                                /> : <div style={{
                                    height: isMobile ? "200px" : "387px",
                                    display: "flex",
                                    justifyContent: "center"
                                }}><LoadingOutlined
                                    style={{fontSize: 30}} spin/></div>
                            }>

                                {isMobile && (
                                    <Title level={5} style={{marginTop: 0}}><strong>{course.title}</strong></Title>
                                )}

                                <Row type="flex" justify="end">
                                    <Space>
                                        <Button icon={<ClockCircleOutlined/>}>{course.time}</Button>
                                    </Space>
                                </Row>
                            </Card>
                        </Col>

                        {!isMobile && (
                            <Col span={0} lg={8}>
                                <Courses type="vertical"/>
                            </Col>
                        )}
                    </Row>

                    {course && (
                        <Tabs defaultActiveKey="1" items={[
                            {
                                key: '1',
                                label: 'Kirjeldus',
                                children: <div>
                                    {course.description.split('\n').map((line, index) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            <br/>
                                        </React.Fragment>
                                    ))}
                                </div>,
                            },
                            {
                                key: '2',
                                label: 'Ülesanded',
                                children:
                                    <div>
                                        <Progress
                                            className="eaten-progress"
                                            percent={round((completedTasksCount / course.tasks.length) * 100)}
                                            size="small"
                                        />

                                        <List
                                            itemLayout="horizontal"
                                            dataSource={course.tasks}
                                            renderItem={(task) => {
                                                return (
                                                    <TaskItem task={task} handleTaskToggle={handleTaskToggle}
                                                              setCourse={setCourse}/>
                                                )
                                            }}
                                        />
                                    </div>
                            },
                        ]}/>
                    )}

                    {isMobile && (
                        <Col span={24} lg={0}>
                            <Courses type="vertical"/>
                        </Col>
                    )}

                    <Row type="flex">
                        <Button style={{marginTop: 12}} onClick={() => navigate("/courses")}><Icon
                            component={LeftOutlined}/> {t('common.back')} </Button>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

const TaskItem = ({task, handleTaskToggle, setCourse}) => {
    const [note, setNote] = useState(task.note || '');
    const [visible, setVisible] = useState(task.note);
    const {t} = useTranslation();

    // Handle textarea change
    const handleChange = (e) => {
        setNote(e.target.value);
    };

    // Handle note submission
    const handleSubmit = async () => {
        if (!note.trim()) {
            message.error('Note cannot be empty!');
            return;
        }


        Api.post(`/v2/tasks/${task.id}/note`, {note: note}).then((res) => {
            res.data.isAvailable = true;

            setCourse(res.data);
            message.success(t('note.saved'));
        }).catch((err) => {
            console.log(err);
        });
    };

    const clickButton = (e) => {
        e.stopPropagation();
        e.preventDefault();

        setVisible(true);
    }

    // Handle blur event to save the note
    const handleBlur = () => {
        if (note.trim()) {
            handleSubmit();
        }
    };

    return (
        <div>
            <List.Item
                actions={!visible ? [<Button icon={<MessageOutlined/>} onClick={clickButton}>{t('add.note')}</Button>] : ''}
            >
                <Checkbox
                    defaultChecked={task.completed}
                    onChange={() => handleTaskToggle(task)}
                >
                    {task.description}
                </Checkbox>
            </List.Item>

            {visible && (
                <div>
                    <TextArea
                        style={{maxWidth: 420, marginTop: 12}}
                        rows={4}
                        value={note}
                        onChange={handleChange}
                        onBlur={handleBlur} // Trigger save on blur
                        placeholder={t('note.placeholder')}
                    />
                </div>
            )}
        </div>
    );
};

export default SingleCourseView;
