var urlParams = new URLSearchParams(window.location.search);
let config =  {
    dev: true,
    lang: null, // correct language is selected in i18n.js
    lang_options : ['et', 'en'] ,
    default_lang: 'et',
    email: 'info@fitlap.ee',
    amember_signup: 'https://fitlap.ee/amember/signup',
    public_app_url: 'https://fitlap.ee',
    domain: 'test',
    currency: '€',
    api: {
        url: 'https://api.fitlap.ee/',
        version: 'v1',
        websocket: 'websocket.fitlap.in',
        websocket_key: 78945613,
        tracking: 'errors' // none|errors|all
    },
    wordpress: {
        author: 3,
        slugs: {
            terms: 'privaatsustingimused',
            cookie_policy: 'kupsiste-kasutamine',
            terms_of_sale: 'muugi-ja-kasutustingmused',
            faq: 'kkk',
        }
    },
    logging: {
        key: 'AIzaSyCzaAhDIhgarutR_hxbWEoXS0xdzcd2eNk',
        service: 'app.fitlap.ee',
        version: '3.7'
    },
    analytics_id: 'G-MMVM1RHGRX', // TODO: get value from .env
    optimize_id: 'GTM-P7Z6RXF',
    experiments: {
        signup: "qh1--qSlQcSGVf304FjfXw"
    },
    facebook: {
        app_id: 1637253883215633,
        pixel_id: 295173281116958,
        locale: 'et_EE',
        group: 'https://www.facebook.com/groups/kaalustalla/'
    },
    helpcrunch: {
        application_name: "fitlapee",
        appId: "b8bf3fba-8bb1-4e4f-b679-ad5eb679b547",
    },
    hotjar: 88355,
    getsitecontrol: '8wlozyrw',
    amplitude: '40081728b586b5d053eec1fe4bfd9416',
    delighted_id: "VgvwS0GgLt8EyM7F",
    //onesignal: 'b8b79718-e97a-4ac4-bf28-6e17f8e0db02',
    url_translations: {
        et: {
            'recipes' : 'retseptid',
            'blog-post': 'postitus',
            'blog': 'blogi',
            'user': 'kasutaja',
            'signup': 'liitu',
            'signup-email': 'liitu/email',
            'forgot-password': 'unustasin-parooli',
            'testimonials': 'edulood',
            'faq': 'kkk',
            'toc': 'privaatsustingimused',
            'cookies': 'kupsiste-kasutamine',
            'terms-of-sale': 'muugi-ja-kasutustingmused',
            'partners': 'partnerid',
            'contact': 'kontakt',
            'shopping-list-public': 'shopping-list-public'
        },
        en: {
            'recipes' : 'recipes',
            'blog-post': 'blog-post',
            'blog': 'blog',
            'user': 'user',
            'signup': 'signup',
            'signup-email': 'signup/email',
            'forgot-password': 'forgot-password',
            'testimonials': 'testimonials',
            'faq': 'faq',
            'toc': 'terms-and-conditions',
            'cookies': 'about-cookies',
            'terms-of-sale': 'terms-of-sale',
            'partners': 'partners',
            'contact': 'contact',
            'shopping-list-public': 'shopping-list-public'
        }
    },
    user: {
        male: 1,
        female: 2,
        goal_lose_weight: 1,
        goal_maintain_weight: 2,
        goal_gain_weight: 3,
    },
    unit_system: {
        metric: 1,
        imperial: 2,
        display_amount: 1,
        display_custom_amount: 2,
        display_amount_imperial: 4,
        display_custom_amount_imperial: 8,
    },
    feature: {
        unit_system_enabled: false,
        imperial_ingredients: false,
        banner: 'https://wp.fitlap.ee/wp-json/acf/v3/options/options',
        hide_banner_for_ios: false,
        shop_link: true,
        basic_promo: false,
        onboarding: {
            enabled: true,
            days: 1,
            messages: [3],
            basic_recipe_ids: {
                1: [18098, 17982, 17978],
                2: [18139, 17999, 18084],
                4: [18094, 18092, 18027],
                8: [17994, 17988, 17985]
            }
        },
        training_groups: ['muscles', 'aerobic', 'stretching'],
        recipe_price: true,
        inactive_banner: "https://fitlap.ee/pere-toitumiskava-2",
        levels: true,
        home_cooks: true,
        selver: true,
        logo_feature: true,
        simple_recipes: false,
        dark_mode: true,
        new_user_info: true,
        google_login: true,
        facebook_login: true,
        fitlap_wrapped: false,
        custom_api: true,
        change_lang: true,
        stebby: true,
        fitlap_plus: true,
        ai_enabled: true,
        fitlap_event: false,
        fitlap_chat: true,
        meal_plan_simple: true,
    },
    recipe: {
        condiments_category: 8
    },
    free_products: [4, 7, 11, 22],
    placeholder_recipe_id: 188162,
    affiliate: "https://fitlap.ee/amember/aff/go/",
    ingredients_limit_40: [513, 288, 814, 607, 108, 301, 222, 39, 187, 527, 291, 88, 300, 717, 123, 278, 279, 654, 52, 277, 320, 606, 723, 167, 40, 221, 508, 442, 183],
    ingredients_limit_25: [205, 503],
    placeholder_ingredient: 10668
};

export default config;
