import {combineReducers} from 'redux';
import user from './userReducer.js';
import menu from './menuReducer.js';
import date from './dateReducer.js';
import recipes from './recipesReducer.js';
import access from './accessReducer.js';
import familyMembers from './familyMembersReducer.js';
import weightsReducer from './weightsReducer.js';
import goalReducer from './goalReducer.js';
import freewareReducer from './freewareReducer.js';
import shoppingListReducer from './shoppingListReducer.js';
import userRecipesReducer from "./userRecipesReducer.js";
import cheatMealReducer from "./cheatMealReducer.js";
import sizesReducer from "./sizesReducer.js";
import dailyActivitiesReducer from "./dailyActivitiesReducer.js";
import notificationsReducer from "./notificationsReducer.js";
import physicalActivitiesReducer from "./physicalActivitiesReducer.js";
import productsReducer from "./productsReducer.js";
import appReducer from "./appReducer.js";
import authorsReducer from "./authorsReducer.js";
import blogFeedReducer from "./blogFeedReducer.js";
import waterReducer from "./waterReducer.js";
import dailyTaskReducer from "./dailyTaskReducer";
import levelsReducer from "./levelsReducer";
import quickStartReducer from "./quickStartReducer";
import messagesReducer from "./messagesReducer";

export default combineReducers({
    user: user,
    menu: menu,
    date: date,
    recipes: recipes,
    access: access,
    family: familyMembers,
    weights: weightsReducer,
    goal: goalReducer,
    freeware: freewareReducer,
    shopping_list: shoppingListReducer,
    user_recipes: userRecipesReducer,
    cheat_meal: cheatMealReducer,
    sizes: sizesReducer,
    daily_activities: dailyActivitiesReducer,
    notifications: notificationsReducer,
    physical_activities: physicalActivitiesReducer,
    products: productsReducer,
    app: appReducer,
    favorite_authors: authorsReducer,
    blog_feed: blogFeedReducer,
    water: waterReducer,
    daily_task: dailyTaskReducer,
    levels: levelsReducer,
    quick_start: quickStartReducer,
    chat_room: messagesReducer
});