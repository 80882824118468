import React, {useEffect, useState} from "react";
import {
    App,
    Badge,
    Col,
    Divider,
    List,
    Modal,
    Progress,
    Row,
    Typography,
    Avatar,
    Tooltip,
    Button,
    Form,
    Input,
    message,
    Alert,
    Popconfirm,
    Tag,
    Space
} from "antd";
import {useTranslation, withTranslation} from "react-i18next";
import api from "../api";
import Icon, {
    QuestionCircleOutlined,
    CheckCircleOutlined,
    RightOutlined,
    UserOutlined,
    HeartFilled,
    InfoCircleOutlined,
    PlusOutlined,
    HeartOutlined
} from "@ant-design/icons";
import {round} from "lodash";
import {isImperialSystem, setCookie} from "../helpers";
import {useResizeEffect} from "../resize-effect";
import {useSelector} from "react-redux";
import {getLevelClassname} from "../helpers/user";

const {Title, Text} = Typography;

function getRandomElement(array) {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
}

const namePrefix = ['tublid', 'vaprad', 'targad', 'säravad', 'õnnelikud', 'edukad', 'tugevad', 'lahked', 'ausad', 'loovad', 'julged', 'rahulikud', 'enesekindlad', 'positiivsed', 'sihikindlad', 'rõõmsad', 'hoolivad', 'usaldusväärsed', 'pühendunud', 'kirglikud', 'uudishimulikud', 'avatud', 'kannatlikud', 'vastutustundlikud', 'meeldivad', 'sõbralikud', 'abivalmid', 'viisakad', 'mõistvad', 'toetavad', 'innustavad', 'andekad', 'oskuslikud', 'kogenud', 'õpihimulised', 'nutikad', 'paindlikud', 'uuendusmeelsed', 'entusiastlikud', 'energilised', 'aktiivsed', 'sportlikud', 'tervislikud', 'tasakaalukad', 'lõbusad', 'humoorikad', 'vaimukad', 'originaalsed', 'erilised', 'ainulaadsed', 'väärtuslikud', 'olulised', 'mõjukad', 'eeskujulikud', 'inspireerivad', 'motiveerivad', 'armastavad', 'avameelsed', 'empaatilised', 'erksad', 'geniaalsed', 'graatsilised', 'head', 'heldekäelised', 'hellad', 'hoolitsevad', 'ideaalsed', 'ilusad', 'imelised', 'imetlusväärsed', 'kaunid', 'kenad', 'kerged', 'kohusetundlikud', 'kompetentsed', 'kvaliteetsed', 'kultuursed', 'leidlikud', 'lojaalsed', 'lummavad', 'maagilised', 'maitsvad', 'mõistlikud', 'mõnusad', 'naeratavad', 'nunnud', 'õilsad', 'õnnistatud', 'õpetlikud', 'õrnad', 'pädevad', 'päikeselised', 'rahulolevad', 'rikkad', 'rõõmustavad', 'siirad', 'sõnaosavad', 'südamlikud', 'sümpaatsed', 'tänulikud', 'teadlikud', 'teotahtelised', 'teravad', 'teretulnud', 'toredad', 'truud', 'tundlikud', 'tähelepanelikud', 'täiuslikud', 'uskumatud', 'vabad', 'vahvad', 'vaimustavad', 'vaimustunud', 'veatud', 'veenvad', 'veetlevad', 'vinged', 'virged', 'voolavad', 'võimekad', 'võluvad', 'võrratud', 'vägevad', 'värvikad',]
const nameAffix = ['alligaatorid', 'alpakad', 'antiloobid', 'armadillod', 'delfiinid', 'dinosaurused', 'draakonid', 'elevandid', 'flamingod', 'gepardid', 'gorillad', 'hamstrid', 'hirved', 'hobused', 'hülged', 'hiired', 'hundid', 'ilvesed', 'jääkarud', 'jänesed', 'kaamlid', 'kaelkirjakud', 'kakaduud', 'kajakad', 'kalad', 'kalkunid', 'kameeleonid', 'kanad', 'kängurud', 'karud', 'kassid', 'kassipojad', 'kilpkonnad', 'kitsekesed', 'koaalad', 'koerad', 'koerakutsikad', 'kolibrid', 'kutsikad', 'küülikud', 'laamad', 'leemurid', 'lemmingud', 'leopardid', 'liblikad', 'linnud', 'lõvid', 'varblased', 'tuvid', 'öökullid', 'kotkad', 'pääsukesed', 'kuldnokad', 'leevikesed', 'tihased', 'luiged', 'pardid', 'roosid', 'tulbid', 'nartsissid', 'orhideed', 'liiliad', 'pojengid', 'päevalilled', 'moonid', 'rukkililled', 'võililled', 'sirelid', 'jasmiinid', 'lavendlid', 'rosmariinid', 'piparmündid', 'salveid', 'basiilikud', 'tüümianid', 'petersellid', 'kased', 'männid', 'kuused', 'haavad', 'lepad', 'tammed', 'saared', 'vahtrad', 'pärnad', 'sarapuud', 'pihlakad', 'toomingad', 'kibuvitsad', 'sõstrad', 'vaarikad', 'maasikad', 'mustikad', 'murakad', 'pohlad', 'jõhvikad', 'rabamurakad', 'tähed', 'päikesekiired', 'seiklejad', 'ettevõtjad', 'sõbrad', 'kartulid', 'porgandid', 'peedid', 'kapsad', 'kaalikad', 'kurgid', 'tomatid', 'paprikad', 'baklažaanid', 'suvikõrvitsad', 'kõrvitsad', 'spinatid', 'porrud', 'redised', 'sellerid', 'oad', 'herned', 'läätsed', 'brokkolid', 'lillkapsad', 'kirsid', 'tibud', 'lepatriinud',]

const FitlapChallenge = ({t}) => {
    const isMobile = useResizeEffect();
    const [modal, setModal] = useState(false);
    const [inviteModal, setInviteModal] = useState(false);
    const [bingoItems, setBingoItems] = useState(false);
    const [challenge, setChallenge] = useState(false);
    const [rewards, setRewards] = useState(false);
    const [terms, setTerms] = useState(false);
    const [group, setGroup] = useState(false);
    const user = useSelector(state => state.user.data);
    const [invites, setInvites] = useState([]);
    const {message} = App.useApp();

    useEffect(() => {
        setCookie('visited_challenge', true, 365);

        getChallenge();

        // Rewards
        api.get('/v2/blog/page?slug=challenge-rewards').then(res => {
            setRewards(res.data.data);
        });

        // Terms
        api.get('/v2/blog/page?slug=challenge-terms').then(res => {
            setTerms(res.data.data);
        });

        // Check for group
        api.get('/v2/user/bingo-challenge/groups').then(res => {
            setGroup(res.data.data);
        })

        // Check for invites
        api.get('/v2/user/bingo-challenge/groups/invites').then(res => {
            setInvites(res.data.data);
        })
    }, [])

    const getChallenge = () => {
        api.get('/v2/user/bingo-challenge').then(res => {
            setChallenge(res.data.data);
            setBingoItems(res.data.data[0]);
        })
    }

    const completeChallengeCard = (item) => {
        api.put('/v2/user/bingo-challenge/' + item.id).then(res => {
            const index = res.data.data.findIndex((i) => {
                return i.id === bingoItems.id;
            });

            setBingoItems(res.data.data[index]);
            setChallenge(res.data.data);

            if (!item.completed) {
                message.success(t('challenge.completed'));
            }

            setModal(false);
        })
    }

    const changeChallenge = (item) => {
        window.scrollTo(0, 0);

        setBingoItems(item);
    }

    const renderParticipants = (item) => {
        if (!item.participants) {
            return null;
        }

        return (<Avatar.Group size={isMobile ? "small" : "normal"} maxCount={2} style={{marginBottom: 20}}>
            {item.participants.map(participant => {
                if (!participant) {
                    return false;
                }

                return (
                    <Tooltip title={t(participant.name)} placement="top">
                        <Avatar src={participant.avatar} icon={<UserOutlined/>}/>
                    </Tooltip>
                )
            })}
        </Avatar.Group>)
    }

    const repondInvite = (response, id) => {
        if (!response) {
            api.delete('/v2/user/bingo-challenge/groups/invites/' + id).then(res => {
                setInvites(false);
            })

            return;
        }

        api.put('/v2/user/bingo-challenge/groups/invites/' + id).then(res => {
            // Invite accepted
            setInvites(false);

            // Reload challenge
            getChallenge();
        })
    }

    const leaveGroup = () => {
        api.delete('/v2/user/bingo-challenge/groups/' + group.id).then(res => {
            getChallenge();

            Modal.destroyAll();

            setGroup(false);
        })
    }

    return (<div className="fitlap-challenge">
        {bingoItems && (<Row type="flex" justify="center" gutter={40}>
            <Col span={22} md={16} xxl={10} style={{marginBottom: 20}}>

                <ChallengeModal group={group} completeChallengeCard={completeChallengeCard} setModal={setModal}
                                item={modal} renderParticipants={renderParticipants}/>

                <InviteModal inviteModal={inviteModal} setInviteModal={setInviteModal} setGroup={setGroup}
                             group={group}/>

                <Divider orientation="left">
                    <Title style={{margin: 0}}
                           level={4}>{bingoItems.name} ({bingoItems.completed}/{bingoItems.items.length})</Title>
                </Divider>

                <Progress rootClassName="challenge-progress" style={{marginBottom: 20}} className={""} strokeWidth={7}
                          percent={bingoItems.items.length > 0 ? round(bingoItems.completed / bingoItems.items.length * 100) : 0}
                          strokeColor="#0EB8AE"/>
                <Row type="flex" justify="center" style={{gap: 8, marginBottom: 20}}>
                    <Button type="primary" size="large" icon={<Icon component={PlusOutlined}/>}
                            onClick={() => setInviteModal(true)}>{t('invite.friend')}</Button>

                    <Button size="large" icon={<Icon component={InfoCircleOutlined}/>}
                            onClick={async () => {
                                Modal.info({
                                    maskClosable: true,
                                    title: t('fitlap-challenge.title-2'),
                                    content: (<>
                                        <p>{t('fitlap-challenge.description')}</p>
                                        {group && (
                                            <Row type="flex" justify="center">
                                                <Popconfirm
                                                    cancelText={t('common.cancel')}
                                                    onConfirm={leaveGroup}
                                                    title={t('leave.group.title')}
                                                    description={t('leave.group.description')}
                                                    icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
                                                >
                                                    <Button danger>{t('leave.group')}</Button>
                                                </Popconfirm>
                                            </Row>
                                        )}
                                    </>),
                                });
                            }}
                    >
                        {t('challenge.info')}
                    </Button>
                </Row>

                {invites && invites.length > 0 && (<Row type="flex" justify="center">
                    {invites.map(invite => {
                        if (invite.accepted) {
                            return false;
                        }

                        return (<Alert
                            style={{marginBottom: 12}}
                            description={<div>
                                <Text>{t('invitation.text', {name: invite.invited_by.name})}</Text>
                                <Row type="flex" justify="center" style={{gap: 8, marginTop: 10}}>
                                    <Button size="small" type="primary"
                                            onClick={() => repondInvite(true, invite.id)}>
                                        {t('accept.invite')}
                                    </Button>
                                    <Button size="small" danger ghost
                                            onClick={() => repondInvite(false, invite.id)}>
                                        {t('decline.invite')}
                                    </Button>
                                </Row>
                            </div>}
                            type="info"
                            showIcon
                            closable
                        />)
                    })}
                </Row>)}

                <Row type="flex" gutter={[10, 10]}>
                    {bingoItems.items.map(item => {
                        const isGroupCompleted = () => {
                            let completed = true;

                            if (item.participants) {
                                item.participants.map(participant => {
                                    if (!participant) {
                                        return false;
                                    }

                                    if (!participant.completed) {
                                        completed = false;
                                    }
                                })
                            }

                            return !group ? false : completed;
                        }


                        return (<Col key={item.id} span={8}>
                            {item.completed ? (<Badge count={!isGroupCompleted() ?
                                <CheckCircleOutlined style={{fontSize: 20, color: "#0EB8AE"}}/> :
                                <HeartFilled style={{fontSize: 20, color: "#0EB8AE"}}/>} color="green">
                                <div onClick={() => setModal(item)}
                                     className={"challenge-card " + (isGroupCompleted() ? "completed" : "")}>
                                    {renderParticipants(item)}

                                    {item.name}
                                </div>
                            </Badge>) : (
                                <div onClick={() => setModal(item)} className={"challenge-card"}>
                                    {renderParticipants(item)}

                                    {item.name}
                                </div>)}
                        </Col>)
                    })}
                </Row>
            </Col>

            <Col span={22} md={16} xxl={6}>
                <div>
                    <Divider orientation="left">
                        <Title style={{margin: 0}} level={4}>{t('challenge.categories')}</Title>
                    </Divider>

                    <Row>
                        <List
                            style={{width: "100%"}}
                            size="large"
                            header={false}
                            footer={false}
                            bordered
                            dataSource={challenge}
                            renderItem={(item) => {
                                return (<List.Item
                                    key={item.id}
                                    onClick={() => changeChallenge(item)}
                                    className="list-item-row"
                                    actions={[item.completed === item.items.length ?
                                        <Icon style={{color: "#52c41a"}}
                                              component={CheckCircleOutlined}/> :
                                        <Icon component={RightOutlined}/>]}
                                >
                                    <Text strong>{item.name} ({item.completed}/{item.items.length})</Text>
                                </List.Item>)
                            }}
                        />
                    </Row>
                </div>

                {rewards && (<div>
                    <Divider orientation="left" style={{marginTop: 30}}>
                        <Title style={{margin: 0}} level={4}>{rewards.title}</Title>
                    </Divider>

                    <Row>
                        <div dangerouslySetInnerHTML={{__html: rewards.content}}></div>
                    </Row>
                </div>)}

                {terms && (<div>
                    <Divider orientation="left" style={{marginTop: 30}}>
                        <Title style={{margin: 0}} level={4}>{terms.title}</Title>
                    </Divider>

                    <Row>
                        <div dangerouslySetInnerHTML={{__html: terms.content}}></div>
                    </Row>
                </div>)}
            </Col>
        </Row>)}
    </div>);
}

const InviteModal = ({setInviteModal, inviteModal, group, setGroup}) => {
    const user = useSelector((state) => state.user.data);
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const prefix = getRandomElement(namePrefix);
    const affix = getRandomElement(nameAffix);
    const groupName = group.name ? group.name : (prefix + ' ' + affix);

    const inviteFriend = () => {
        form.validateFields().then((values) => {
            // User does not have group yet
            if (!group) {
                api.post('/v2/user/bingo-challenge/groups', {name: groupName}).then(res => {
                    setGroup(res.data.data);

                    api.post('/v2/user/bingo-challenge/groups/invites', {
                        group_id: res.data.data.id, email: values.email
                    }).then(res => {
                        console.log(res);

                        form.resetFields();

                        message.success(t('invite.sent'))
                    })
                })
            } else {
                api.post('/v2/user/bingo-challenge/groups/invites', {
                    group_id: group.id, email: values.email
                }).then(res => {
                    message.success(t('invite.sent'))

                    form.resetFields();

                    setInviteModal(false);
                }).catch(err => {
                    message.error(t('invite.failed'))
                })
            }
        });
    }

    const updateGroupName = (e) => {
        api.put('/v2/user/bingo-challenge/groups/' + group.id, {name: e.target.value}).then(res => {
            setGroup(res.data.data);

            message.success(t('name.updated'))
        })
    }

    return (
        <Modal
            destroyOnClose={true}
            className="challenge-modal"
            title={false}
            open={inviteModal}
            onOk={inviteFriend}
            onCancel={() => setInviteModal(false)}
            okText={t('invite.friend')}
        >
            <Row className="text-center" type="flex" align="middle" justify="center">
                <Form form={form} wrapperCol={{span: 20}} labelCol={{span: 24}} className="Item">
                    {group.user_id === user.user_id && (
                        <Form.Item
                            label={t('group.name')}
                            name="group_name"
                            initialValue={groupName}
                        >
                            <Input onBlur={updateGroupName} size="large"/>
                        </Form.Item>
                    )}

                    <Form.Item
                        label={t('settings.personal.email')}
                        name="email"
                        rules={[{
                            type: 'email', message: t('login.form.error.email-invalid'),
                        }, {
                            required: true, message: t('login.form.error.email-required')
                        }]}
                    >
                        <Input size="large" prefix={<UserOutlined className="site-form-item-icon"/>}
                               placeholder={t('login.form.email')}/>
                    </Form.Item>
                </Form>
            </Row>
        </Modal>)
}


const ChallengeModal = ({item, setModal, completeChallengeCard, group}) => {
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        if (!item && loading) {
            setLoading(false);
        }
    }, [item])

    return (<Modal
        className="challenge-modal"
        title={false}
        open={!!item}
        okButtonProps={{disabled: loading}}
        onOk={() => {
            setLoading(true);
            completeChallengeCard(item);
        }}
        onCancel={() => setModal(false)}
        okText={item.completed ? t('mark.uncomplete') : t('mark.completed')}
    >
        <Row type="flex" justify="center" align="middle" className="text-center">
            <h2>{item.name}</h2>
        </Row>

        <Space direction="vertical" style={{width: "100%"}}>
            {item.participants && (<Row style={{gap: 12}} type="flex" justify="center" align="middle">
                {item.participants.map(participant => {
                    if (!participant) {
                        return false;
                    }

                    return (
                        <Tooltip rootClassName={"tooltip-in-modal"} title={t(participant.name)} placement="top">
                            <Badge count={"✔"} color={participant.completed ? "green" : "grey"}>
                                <Avatar src={participant.avatar} icon={<UserOutlined/>}/>
                            </Badge>
                        </Tooltip>)
                })}
            </Row>)}

            {group && (<Row style={{gap: 12}} type="flex" justify="center" align="middle">
                <Tag rootClassName="team-name">{group.name}</Tag>
            </Row>)}
        </Space>

        <Row type="flex" justify="center" align="middle" className="text-center">
            <div dangerouslySetInnerHTML={{__html: item.description}}/>
        </Row>
    </Modal>)
}

export default withTranslation()(FitlapChallenge);
