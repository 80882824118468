import {FETCH_CHAT_ROOMS_SUCCESS, FETCH_CHAT_ROOMS_FAILURE} from '../actions/index';

const initialState = {
    data: [],
    loading: true,
    error: null
};

export default function messagesReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_CHAT_ROOMS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            };
        case FETCH_CHAT_ROOMS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                data: []
            };
        default:
            return state;
    }
}